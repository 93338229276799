<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text"
      ><!-- {{isUpdate?'แก้ไข':'สร้าง'}} -->ข้อมูลที่อยู่ลูกค้า</v-card-title
    >
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="4">
          <v-text-field
            class="pt-2 "
            name="address"
            v-model="form.address"
            label="ที่อยู่"
            outlined
            dense
            background-color="white"
          />
          <!--<v-text-field name="building" v-model="form.building" label="อาคาร" outlined dense background-color="white"/> -->
          <InputAutoComplete
            key_name="district"
            :value="form.sub_district"
            name="form.sub_district"
            label="ตำบล"
            @onChangeAddress="onChangeAddress"
            @onChange="value => (form.sub_district = value)"
          />

          <InputAutoComplete
            key_name="amphoe"
            :value="form.district"
            name="form.district"
            label="อำเภอ"
            @onChangeAddress="onChangeAddress"
            @onChange="value => (form.district = value)"
          />

          <InputAutoComplete
            key_name="province"
            :value="form.province"
            name="form.province"
            label="จังหวัด"
            @onChangeAddress="onChangeAddress"
            @onChange="value => (form.province = value)"
          />

          <InputAutoComplete
            key_name="zipcode"
            :value="form.postal_code"
            name="form.postal_code"
            label="รหัสไปรษณีย์"
            @onChangeAddress="onChangeAddress"
            @onChange="value => (form.postal_code = `${value}`)"
            :error_messages="$v.form.postal_code.$error ? $error_text : ''"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()"
        >ยกเลิก</v-btn
      >
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
        >บันทึกข้อมูล</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  numeric,
  integer,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import InputAutoComplete from '@/components/Address/InputAutoComplete';

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  components: {
    InputAutoComplete
  },
  validations: {
    form: {
      postal_code: {
        numeric,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      }
    }
  },
  data() {
    return {
      loading: false,
      form: this.item
    };
  },
  watch: {
    item: function(newVal, oldVal) {
      this.form = newVal;
      this.$v.form.$reset();
    }
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.$emit('onSubmit', this.form);
    },
    onChangeAddress(data) {
      this.form.sub_district = data.district;
      this.form.district = data.amphoe;
      this.form.province = data.province;
      this.form.postal_code = `${data.zipcode}`;
    }
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
