<template>
  <span>
    <span @click="print">
      <slot></slot>
    </span>

    <div style="display: none">
      <div id="printJS-form">
        <template v-for="(docPage, doc_page_index) in form">
          <div :key="doc_page_index">
            <template v-if="form[doc_page_index].copy_product.length">
              <table :border="tableBorder" width="100%">
                <tbody>
                  <template v-for="(itemPage, index) in 1">
                    <div :key="index">
                      <div
                        width="'100%'"
                        v-if="
                          itemPage != 2 &&
                          !(docTypeId == 0 && itemPage == 3)
                        "
                      >
                        <table
                          :border="tableBorder"
                          width="100%"
                          class="text-center"
                        >
                          <tbody>
                            <tr>
                              <td width="100%" valign="top">
                                <img
                                  :src="`https://permtang.s3.ap-southeast-1.amazonaws.com/car_registration_book/6827328839358802.jpeg`"
                                  alt=""
                                  width="45"
                                  height="auto"
                                />
                                <div class="customer">
                                  <div>
                                    {{ form[doc_page_index].name_for_receipt }}
                                    {{ form[doc_page_index].company_branch }}

                                    <span
                                      class="text-header-detail"
                                      v-if="
                                        form[doc_page_index].taxpayer_number !=
                                        ''
                                      "
                                    >
                                      {{ form[doc_page_index].taxpayer_number }}
                                    </span>
                                  </div>

                                  <div class="text-header-detail">
                                    {{ form[doc_page_index].location.address }}
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .sub_district != ''
                                      "
                                      >{{
                                        form[doc_page_index].location
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .district != ''
                                      "
                                    >
                                      {{
                                        form[doc_page_index].location.district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].location
                                          .province != ''
                                      "
                                    >
                                      {{
                                        form[doc_page_index].location.province
                                      }}</template
                                    >
                                    {{
                                      form[doc_page_index].location.postal_code
                                    }}
                                  </div>

                                  <div class="text-header-detail">เปิด <span v-html="form[doc_page_index].open_hours"></span></div>
                                  <div class="text-header-detail">
                                    โทร.
                                    {{ form[doc_page_index].phone_number }}
                                  </div>
                                  <div>
                                    <span
                                      v-if="form[doc_page_index].facebook"
                                      class="text-header-detail"
                                    >
                                      Facebook:
                                      {{ form[doc_page_index].facebook }}
                                    </span>
                                    <span
                                      v-if="form[doc_page_index].line_id"
                                      class="text-header-detail"
                                    >
                                      Line ID:
                                      {{ form[doc_page_index].line_id }}
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="line"></div>
                        <table :border="tableBorder" width="100%">
                          <tbody>
                            <tr>
                              <td width="100%" valign="top" align="center">
                                <div class="text-header my-3" v-if="form[doc_page_index].vat_price > 0 && docTypeId == 2">
                                  ใบเสร็จรับเงิน/ใบกำกับภาษี
                                </div>
                                <div class="text-header my-3" v-else>
                                  {{ documentName }}
                                </div>
                                
                                <!-- <div
                                  v-if="docTypeId != 0"
                                  class="font-size-12"
                                >
                                  {{
                                      itemPage == 1 && doc_page_index == 0
                                        ? 'ตัวจริง'
                                        : doc_page_index == 0
                                        ? 'สำเนา'
                                        : ''
                                    }}
                                    {{
                                      (setting.print_one_page
                                        ? itemPage == 2
                                        : itemPage == 1) && doc_page_index == 1
                                        ? 'ตัวจริง'
                                        : doc_page_index == 1
                                        ? 'สำเนา'
                                        : ''
                                    }}
                                </div> -->

                                <table :border="tableBorder" width="100%">
                                  <tbody>
                                    <tr>
                                      <td
                                        width="50%"
                                        class="text-header-detail"
                                      >
                                        เลขที่ :
                                        {{ form[doc_page_index].number }}
                                      </td>
                                      <td
                                        class="text-header-detail"
                                        v-if="docTypeId != 0"
                                      >
                                        อ้างอิง :
                                        {{
                                          form[doc_page_index]
                                            .reference_document
                                        }}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td class="text-header-detail">
                                        วันที่ :
                                        {{
                                          formatDate(
                                            form[doc_page_index].date_of_issue
                                          )
                                        }}
                                        &nbsp;
                                        {{
                                          form[doc_page_index].date_of_issue
                                            | moment($formatTime)
                                        }}น.
                                      </td>
                                      <td class="text-header-detail">
                                        พนักงาน :
                                        {{ form[doc_page_index].approval }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="line"></div>

                        <div>
                          <div>
                            <div class="text-header">
                              {{ form[doc_page_index].customer_name }}
                              <span class="text-header-detail" v-if="form[doc_page_index].customer_company_branch">
                               <!-- เลขผู้เสียภาษี : -->
                              (สาขา {{form[doc_page_index].customer_company_branch}})
                              </span>
                              <span
                                class="text-header-detail"
                                v-if="
                                  form[doc_page_index]
                                    .customer_taxpayer_number != ''
                                "
                              >
                                <!-- เลขผู้เสียภาษี : -->

                                {{
                                  form[doc_page_index].customer_taxpayer_number
                                }}
                              </span>
                            </div>
                            <div
                              class="text-header-detail"
                              v-if="
                                form[doc_page_index].customer_taxpayer_number !=
                                ''
                              "
                            >
                              {{
                                form[doc_page_index].customer_address.address
                              }}
                              <template
                                      v-if="
                                        form[doc_page_index].customer_address
                                          .province != 'กรุงเทพมหานคร' && (form[doc_page_index].customer_address
                                          .sub_district != '')
                                      "
                                    >
                                      ต.{{
                                        form[doc_page_index].customer_address
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-else-if="
                                        form[doc_page_index].customer_address
                                          .province === 'กรุงเทพมหานคร' && form[doc_page_index].customer_address
                                          .sub_district != ''
                                      "
                                    >
                                      แขวง{{
                                        form[doc_page_index].customer_address
                                          .sub_district
                                      }}</template
                                    >
                                    <template
                                      v-if="
                                        form[doc_page_index].customer_address
                                          .district != '' && form[doc_page_index].customer_address
                                          .province != 'กรุงเทพมหานคร'
                                      "
                                    >
                                      อ.{{
                                        form[doc_page_index].customer_address
                                          .district
                                      }}</template
                                    >
                                    <template
                                      v-else-if="
                                        form[doc_page_index].customer_address
                                          .district != '' && form[doc_page_index].customer_address
                                          .province === 'กรุงเทพมหานคร'
                                      "
                                    >
                                      เขต{{
                                        form[doc_page_index].customer_address
                                          .district
                                      }}</template
                                    >
                              <template
                                v-if="
                                  form[doc_page_index].customer_address
                                    .province != ''
                                "
                              >
                                {{
                                  form[doc_page_index].customer_address.province
                                }}</template
                              >
                              {{
                                form[doc_page_index].customer_address
                                  .postal_code
                              }}
                            </div>
                          </div>
                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr
                                class="text-header-detail"
                                v-if="form[doc_page_index].discounted_groups_id"
                              >
                                <td class="text-header-detail">
                                  กลุ่ม :
                                  {{
                                    form[doc_page_index].discounted_groups_id
                                  }}
                                </td>
                                <td
                                  class="text-header-detail"
                                >
                                  เล่มทะเบียนรถ :
                                  {{
                                    form[doc_page_index].is_original_document
                                      ? 'เล่มจริง'
                                      : 'สำเนา'
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="text-header-detail"
                                  v-if="tax_include_fines == true"
                                >
                                  จดทะเบียน:
                                  {{
                                    formatDate(
                                      form[doc_page_index].registration_date
                                    )
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div
                            class="text-carPlate"
                            v-if="
                              setting.show_customer_phone_number &&
                              form[doc_page_index].customer_phone_number != ''
                            "
                          >
                            โทร:
                            {{
                              permission.show_phone_number 
                              ? form[doc_page_index].customer_phone_number
                              : form[doc_page_index].customer_phone_number.substring(0, form[doc_page_index].customer_phone_number.length-4) + 'XXXX'
                            }}

                            <!-- {{
                              itemPage == 1 &&
                              form[doc_page_index].call_alert
                                ? 'โทรแจ้งลูกค้า เมื่องานเสร็จ'
                                : ''
                            }}
                            {{
                              (
                                setting.print_one_page
                                  ? itemPage == 1
                                  : itemPage == 1
                              )
                                ? ''
                                : doc_page_index == 1 &&
                                  form[doc_page_index].call_alert
                                ? 'โทรแจ้งลูกค้า เมื่องานเสร็จ'
                                : ''
                            }} -->

                            <template v-if="form[doc_page_index].call_alert">
                              <span class="text-header-detail"
                                >(โทรแจ้งลูกค้า เมื่องานเสร็จ)</span
                              >
                            </template>
                          </div>

                          <div class="text-carPlate">
                            {{ form[doc_page_index].car_plate }}
                            {{ form[doc_page_index].car_province }}
                            {{ setting.show_project_name ? form[doc_page_index].project_name : '' }}
                          </div>

                          <div class="line"></div>
                          <table
                            :border="tableBorder"
                            width="100%"
                            class="table text-product-detail product-margin"
                          >
                            <tbody>
                              <tr
                                v-for="(item, i) in form[doc_page_index]
                                  .original_product.length != 0 && itemPage == 1
                                  ? form[doc_page_index].original_product
                                  : form[doc_page_index].copy_product"
                                :key="i"
                              >
                                <td
                                  align="left"
                                  valign="top"
                                  class="text-product"
                                >
                                  {{ i + 1 }}
                                </td>
                                <td
                                  valign="top"
                                  class="pt-0 text-product-detail"
                                >
                                  <div v-if="item['start_date'] !== undefined">
                                    <div class="text-product">
                                      {{ item.name + ' ' + item.broker }}
                                    </div>
                                    <template
                                      v-if="
                                        (itemPage == 1 &&
                                          doc_page_index == 0) ||
                                        ((setting.print_one_page
                                          ? itemPage == 3
                                          : itemPage == 1) &&
                                          doc_page_index == 1)
                                      "
                                    >
                                      <div
                                        class="text-product-detail"
                                        v-if="
                                          setting.show_3rd_insurance_protection_date &&
                                          item['category_id'] == 'PC1'
                                        "
                                      >
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                      <div
                                        class="text-product-detail"
                                        v-if="
                                          setting.show_insurance_protection_date &&
                                          item['category_id'] == 'PC2'
                                        "
                                      >
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div class="text-product-detail">
                                        คุ้มครองวันที่
                                        {{ formatDate(item.start_date) }}
                                        -
                                        {{ formatDate(item.end_date) }}
                                      </div>
                                    </template>
                                  </div>
                                  <div v-else class="mb-0">
                                    <div class="text-product">
                                      {{ item.name }}
                                    </div>
                                    <div class="text-header">
                                      {{ item.detail }}
                                    </div>
                                    <div></div>
                                  </div>
                                </td>
                                <!-- <td class="table-td" align="right">
                                    {{ item.product_price | numeral("0,0.00") }}
                                  </td>
                                  <td class="table-td" align="right">
                                    {{ item.discount | numeral("0,0.00") }}
                                  </td>
                                  <td v-if="setting.vat_registration" class="table-td" align="right">
                                    {{ item.vat ? "7%" : "0%" }}
                                  </td> -->
                                <td align="right" valign="top">
                                  <div class="text-right text-product">
                                    {{ item.price | numeral('0,0.00') }}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="line"></div>
                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td valign="top">
                                  <div>
                                    <table :border="tableBorder" width="100%">
                                      <tbody
                                        v-if="
                                          form[doc_page_index].document_type >=
                                          3
                                        "
                                      >
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              มูลค่าตามเอกสารเดิม
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index]
                                                  .original_document_price
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              มูลค่าที่ถูกต้อง
                                            </div>
                                            <div class="align-right" v-show="docTypeId == 3
                                          ">
                                              {{
                                                (form[doc_page_index].original_document_price - form[doc_page_index].net_price - form[doc_page_index].vat_price)
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                            <div class="align-right" v-show="docTypeId == 4
                                          ">
                                              {{
                                                (form[doc_page_index].original_document_price + form[doc_page_index].net_price)
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">ผลต่าง</div>
                                            <div class="align-right">
                                              {{
                                                (form[doc_page_index].differrence_price + form[doc_page_index].vat_price)
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="line-s"></div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              รวมเป็นเงิน
                                            </div>
                                            <div class="align-right">
                                              {{
                                                (form[doc_page_index].differrence_price + form[doc_page_index].vat_price)
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0
                                          "
                                        >
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              ส่วนลดรวม
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index]
                                                  .total_discount
                                                  | numeral('0,0')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <!-- <td width="75%">
                                            <div class="line-s"></div>
                                          </td> -->
                                        </tr>
                                        
                                        <tr>
                                          <td align="right"></td>
                                          <td>
                                            <div><br /></div>
                                          </td>
                                          <td align="right"><div></div></td>
                                        </tr>
                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              ราคาก่อนภาษี
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].net_price - form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>

                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              ภาษีมูลค่าเพิ่ม 7%
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div
                                              class="align-left"
                                              :class="
                                                docTypeId == 3 ||
                                                docTypeId == 4
                                                  ? 'amount'
                                                  : ''
                                              "
                                            >
                                              จำนวนเงินรวมทั้งสิ้น
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].net_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>

                                        

                                        <tr>
                                          <td align="right "></td>
                                          <td align="left">
                                            <div class="line-bold"></div>
                                          </td>
                                        </tr>
                                        <tr></tr>
                                      </tbody>

                                      <!-- Quotation, Invoice, Receipt -->
                                      <tbody
                                        v-if="
                                          form[doc_page_index].document_type < 3
                                        "
                                      >
                                        <tr
                                          v-show="
                                            (form[doc_page_index]
                                              .total_discount > 0)
                                          "
                                        >
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              รวมเป็นเงิน
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].total_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-show="
                                            form[doc_page_index]
                                              .total_discount > 0
                                          "
                                        >
                                          <td align="right"></td>
                                          <td>
                                            <div class="align-left">
                                              ส่วนลดรวม
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index]
                                                  .total_discount
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        
                                        <tr>
                                          <td align="right"></td>
                                          <td>
                                            <div></div>
                                          </td>
                                          <td align="right"><div></div></td>
                                        </tr>
                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              ราคาก่อนภาษี
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].net_price - form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>

                                        <tr v-if="form[doc_page_index].vat_price > 0">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              ภาษีมูลค่าเพิ่ม 7%
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              จำนวนเงินรวมทั้งสิ้น
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].net_price | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        
                                        <!-- <tr v-show="taxFreeProducts > 0">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              มูลค่าสินค้าที่ ไม่ต้องเสียภาษี  
                                            </div>
                                            <div class="align-right">
                                              {{
                                                taxFreeProducts | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>

                                        <tr v-show="taxProducts > 0 && doc_page_index == 1 && setting.vat_registration">
                                          <td align="right" width="25%">
                                            <div></div>
                                          </td>
                                          <td width="75%">
                                            <div class="align-left">
                                              มูลค่าสินค้าที่ ต้องเสียภาษี  
                                            </div>
                                            <div class="align-right">
                                              {{
                                                taxProducts | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr> -->

                                        <!-- <tr v-show="form[doc_page_index].vat_price > 0">
                                          <td align="right"></td>
                                          <td>
                                            <div class="align-left">
                                              ภาษีมูลค่าเพิ่ม 7%
                                            </div>
                                            <div class="align-right">
                                              {{
                                                form[doc_page_index].vat_price
                                                  | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr> -->

                                        <tr>
                                          <td align="right "></td>
                                          <td align="left">
                                            <div class="line-s"></div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            setting.show_payment_amount &&
                                            !(
                                              type == 'ใบเสนอราคา' ||
                                              type == 'ใบแจ้งหนี้'
                                            )
                                          "
                                        >
                                          <td align="right"></td>
                                          <td>
                                            <div class="align-left">
                                              รับเงินมา
                                            </div>
                                            <div class="align-right">
                                              {{
                                                summary.payment_amount[
                                                  doc_page_index
                                                ] | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="
                                            setting.show_remaining_amount &&
                                            !(
                                              type == 'ใบเสนอราคา' ||
                                              type == 'ใบแจ้งหนี้'
                                            )
                                          "
                                        >
                                          <td align="right"></td>
                                          <td>
                                            <div class="align-left">
                                              เงินทอน
                                            </div>
                                            <div class="align-right">
                                              {{
                                                summary.remain_change[
                                                  doc_page_index
                                                ] | numeral('0,0.00')
                                              }}
                                              บาท
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td align="right"></td>
                                          <td align="left">
                                            <div class="line-bold"></div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div
                            v-if="
                              docTypeId == 2 ||
                              docTypeId == 3 ||
                              docTypeId == 4
                            "
                          >
                            <div>
                              <table :border="tableBorder" width="100%">
                                <tbody>
                                  <tr class="note">
                                    <td>
                                      <label
                                        v-if="
                                          docTypeId == 2 ||
                                          docTypeId == 4
                                        "
                                      >
                                        ชำระแล้วทาง:
                                      </label>
                                      <label v-if="docTypeId == 3">
                                        คืนเงินทาง:
                                      </label>
                                      <label
                                        v-if="
                                          form[doc_page_index].payment.type == 1
                                        "
                                      >
                                        เงินสด
                                      </label>
                                      <label
                                        v-if="
                                          form[doc_page_index].payment.type == 2
                                        "
                                      >
                                        โอน
                                      </label>
                                      <label
                                        v-if="
                                          form[doc_page_index].payment.type == 3
                                        "
                                      >
                                        บัตร
                                      </label>
                                      <label v-if="form[doc_page_index].payment.date!==''">
                                        <span>
                                          วันที่:
                                        </span>
                                        {{
                                          formatDate(form[doc_page_index].payment.date) || '-'
                                        }}
                                      </label>
                                      <label>
                                        รายละเอียด:
                                        {{
                                          form[doc_page_index].payment
                                            .reference || '-'
                                        }}
                                      </label>
                                      <label v-if="form[doc_page_index].payment.cashier_name!==''">
                                        <span v-if="docTypeId == 2 || docTypeId == 4">
                                          ผู้รับเงิน:
                                        </span>
                                        <span v-if="docTypeId == 3">
                                          ผู้คืนเงิน:
                                        </span>
                                        {{
                                          form[doc_page_index].payment.cashier_name || '-'
                                        }}
                                      </label>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <table
                            v-if="docTypeId != 0"
                            :border="tableBorder"
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td align="center" style="width: 100%">
                                  <div
                                    class="border-radius my-5"
                                    v-if="
                                      (docTypeId == 1 ||
                                      docTypeId == 2) &&
                                      form[doc_page_index].show_pickup
                                    "
                                  >
                                    <div class="text-project">
                                      รับเอกสารได้วันที่
                                    </div>
                                    <div class="text-project">
                                      {{
                                        formatDate(
                                          form[doc_page_index].pick_up_date
                                        )
                                      }}
                                      {{ form[doc_page_index].pick_up_time }}
                                    </div>
                                    <!-- <br/> -->
                                    <!-- <div
                                      v-if="
                                        (docTypeId == 2 ||
                                          docTypeId == 1) &&
                                          itemPage == 1
                                      "
                                    >
                                      <div>
                                        <canvas :id="'canvas' + (doc_page_index + 1) + '' + (index + 1)"></canvas>
                                      </div>
                                      <div class="pb--2">
                                        สแกน QR Code เพื่อติดตามงาน
                                      </div>
                                      <br />
                                    </div> -->
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td valign="top" style="width: 100%">
                                  <table :border="tableBorder" width="100%">
                                    <tbody>
                                      <tr
                                        v-if="form[doc_page_index].note != ''"
                                      >
                                        <td style="width: 100%" valign="top">
                                          <div class="text-header-detail mb-2">
                                            หมายเหตุท้ายเอกสาร
                                          </div>
                                          <div class="text-note">
                                            {{ form[doc_page_index].note }}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div
                                    v-if="
                                      docTypeId == 2 ||
                                      docTypeId == 1
                                    "
                                  >
                                    <div class="line my-5"></div>
                                  </div>

                                  <div
                                    v-if="
                                      form[doc_page_index].delivery_address
                                        .postal_code &&
                                      form[doc_page_index]
                                        .customer_is_delivery_address
                                    "
                                  >
                                    <div class="border-radius text-left">
                                      <div class="text-header">
                                        ที่อยู่จัดส่ง
                                      </div>
                                      <div class="text-product-detail">
                                        {{
                                          form[doc_page_index].delivery_address
                                            .address
                                        }}
                                        <template
                                          v-if="
                                            form[doc_page_index]
                                              .delivery_address.sub_district !=
                                            ''
                                          "
                                        >
                                          {{
                                            form[doc_page_index]
                                              .delivery_address.sub_district
                                          }}</template
                                        >
                                        <template
                                          v-if="
                                            form[doc_page_index]
                                              .delivery_address.district != ''
                                          "
                                        >
                                          {{
                                            form[doc_page_index]
                                              .delivery_address.district
                                          }}</template
                                        >
                                        <template
                                          v-if="
                                            form[doc_page_index]
                                              .delivery_address.province != ''
                                          "
                                        >
                                          {{
                                            form[doc_page_index]
                                              .delivery_address.province
                                          }}</template
                                        >
                                        {{
                                          form[doc_page_index].delivery_address
                                            .postal_code
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                  <template
                                    v-if="
                                      form[doc_page_index].remain_invoice
                                        .length > 1
                                    "
                                  >
                                    <div
                                      v-if="docTypeId == 2"
                                      class="text-center text-header-detail"
                                    >
                                      ใบแจ้งหนี้ ที่รอการชำระ
                                    </div>
                                    <div
                                      v-if="docTypeId == 1"
                                      class="text-center text-header-detail"
                                    >
                                      ใบแจ้งหนี้ทั้งหมดของโปรเจ็คนี้
                                    </div>
                                    <table
                                      v-if="
                                        docTypeId == 2 ||
                                        docTypeId == 1
                                      "
                                      :border="tableBorder"
                                      width="100%"
                                      class="pt-0"
                                    >
                                      <tbody class="invoice-tbody">
                                        <tr>
                                          <td align="left" style="width: 5%">
                                            #
                                          </td>
                                          <td align="left" style="width: 25%">
                                            เลขที่
                                          </td>
                                          <td align="left" style="width: 25%">
                                            ชำระก่อน
                                          </td>
                                          <td align="left" style="width: 25%">
                                            ราคา
                                          </td>
                                          <td align="left" style="width: 15%">
                                            สถานะ
                                          </td>
                                        </tr>
                                        <tr
                                          v-for="(
                                            invoice, invoice_index
                                          ) in form[doc_page_index]
                                            .remain_invoice"
                                          :key="invoice_index"
                                        >
                                          <td align="left" style="width: 5%">
                                            {{ invoice_index + 1 }}
                                          </td>
                                          <td align="left" style="width: 25%">
                                            {{ invoice.number }}
                                          </td>
                                          <td align="left" style="width: 25%">
                                            {{ formatDate(invoice.due_date) }}
                                          </td>
                                          <td align="left" style="width: 25%">
                                            {{
                                              invoice.net_price
                                                | numeral('0,0.00')
                                            }}
                                          </td>
                                          <td align="left" style="width: 20%">
                                            <div class="">
                                              {{
                                                invoice.status == 0
                                                  ? 'ค้างจ่าย'
                                                  : 'จ่ายแล้ว'
                                              }}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      v-if="
                                        docTypeId == 2 ||
                                        docTypeId == 1
                                      "
                                      :border="tableBorder"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="width: 10%"
                                          ></td>
                                          <td
                                            align="left"
                                            style="width: 25%"
                                          ></td>
                                          <td align="left" style="width: 25%">
                                            ยอดรวม
                                          </td>
                                          <td align="right" style="width: 20%">
                                            {{
                                              form[doc_page_index]
                                                .remain_net_price
                                                | numeral('0,0.00')
                                            }}
                                          </td>
                                          <td
                                            align="left"
                                            style="width: 20%"
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <!-- <br v-for="(br, brindex) in 2" :key="brindex" /> -->

                          <!-- <div class="signature-footer">
                            <table :border="tableBorder" width="100%">
                              <tbody>
                                <tr>
                                  <td align="center" style="width: 50%">
                                    <div>.................................</div>
                                    <div>{{ signatureLeft }}</div>
                                  </td>

                                  <td style="text-align: center">
                                    <div></div>
                                  </td>

                                  <td align="center" style="width: 50%">
                                    <div>.................................</div>
                                    <div>{{ signatureRight }}</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div> -->
                        </div>
                      </div>
                      <div class="text-center" v-if="(docTypeId < 2) || (docTypeId == 2 && setting.show_qr_code_payment == 1)">
                          <div class="text-note" v-if="form[doc_page_index].qr_code_payment">QR Code ชำระเงิน</div>
                              <div v-if="form[doc_page_index].qr_code_payment != '' && form[doc_page_index].qr_code_payment">
                                  <img
                                        :src="form[doc_page_index].qr_code_payment"
                                        alt=""
                                        width="150"
                                        height="auto"
                                  />
                              </div>
                          </div>
                      <div
                        v-if="doc_page_index == 0 && data && data2"
                        class="pagebreak"
                      ></div>
                    </div>
                  </template>
                </tbody>
              </table>
            </template>
          </div>
        </template>
      </div>
    </div>
  </span>
</template>

<script>
import printJS from 'print-js';
//import QRCode from 'qrcode';
import * as moment from 'moment';

export default {
  props: {
    type: {
      required: true,
      type: String,
      default: 'ใบแจ้งหนี้', //  1 ใบเสนอราคา, 2 ใบแจ้งหนี้, 3 ใบเสร็จรับเงิน, 4 ใบเพิ่มหนี้, 5 ใบลดหนี้
    },
    data: {
      required: false,
      type: Object,
      default: null,
    },
    data2: {
      required: false,
      type: Object,
      default: null,
    },
    docTypeId: {
      required: false,
      type: Number,
      default: 0,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    firstCreated: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableBorder: 0,
      setting: '',
      permission: {},
      temp: null,
      summary: {
        payment_amount: [],
        remain_change: [],
      },
      tax_include_fines: false,
      form: [
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
        },
        /* form 2 */
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
        },
      ],
    };
  },

  created() {
    this.getUserGroup();
    if (this.data) {
      this.form[0] = { ...this.form[0], ...this.data };
    }
    if (this.data2 && this.data) {
      this.form[1] = { ...this.form[1], ...this.data2 };
      // if (this.form[1].logo_url == '')
      //   this.form[1].logo_url = this.form[0].logo_url;
      this.hasSplitBill = true;
      this.total_price =
        this.form[0].net_price +
        this.form[1].net_price;
    } else if (this.data2 && !this.data) {
      this.form[0] = { ...this.form[0], ...this.data2 };
      // if (this.form[1].logo_url == '')
      //   this.form[1].logo_url = this.form[0].logo_url;
      this.hasSplitBill = true;
      this.total_price = this.form[0].net_price;
      this.summary.payment_amount.push(this.form[0].payment.amount);
      this.summary.remain_change.push(
        (this.hasSplitBill = this.form[0].payment.amount - this.total_price)
      );
    } else {
      this.form.pop(this.form[1]);
    }
    this.getBranchSetting();
    this.summary.payment_amount.push(
      this.hasSplitBill
        ? this.form[0].net_price
        : this.form[0].payment.amount
    );
    this.summary.payment_amount.push(
      this.hasSplitBill
        ? this.form[1].payment.amount -
            (this.form[0].net_price)
        : 0
    );
    this.summary.remain_change.push(
      this.hasSplitBill
        ? 0
        : this.form[0].payment.amount - this.form[0].net_price
    );
    this.summary.remain_change.push(
      this.hasSplitBill ? this.form[1].payment.amount - this.total_price : 0
    );
  },

  methods: {
    async getBranchSetting() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
      });
      this.setting = listFiltered[0].setting;
        if (this.setting.print_one_page && this.data2) {
          this.temp = [...this.form[1].original_product];
          this.form[1].original_product = [...this.form[1].copy_product];
          this.form[1].copy_product = [...this.temp];
        }
        if (this.setting.show_late_tax_fine && this.firstCreated) {
          for (var i = 0; i < this.form.length; i++) {
            for (var j = 0; j < this.form[i].copy_product.length; j++) {
              if (this.form[i].copy_product[j].tag == 'tax_include_fines') {
                this.form[i].copy_product[j].name = this.form[i].copy_product[
                  j
                ].name.concat(' ', this.form[i].copy_product[j].print_detail);
                break;
              }
            }
            if (!this.setting.is_split_bill) {
              for (var j = 0; j < this.form[i].original_product.length; j++) {
                if (
                  this.form[i].original_product[j].tag == 'tax_include_fines'
                ) {
                  var price = this.form[i].original_product[j].price;
                  this.form[i].original_product[j].name = this.form[
                    i
                  ].original_product[j].name.concat(
                    ' ',
                    this.form[i].original_product[j].print_detail
                  );
                  break;
                }
              }
            }
          }
          this.firstCreated = false;
        }
    },
    print() {
      if (!this.disabled) {
        // let canvas1 = document.getElementById('canvas11');
        // let canvas2 = document.getElementById('canvas13');
        // let canvas3 = null;
        // let canvas4 = null;
        // if (this.data2) {
        //   canvas3 = document.getElementById('canvas21');
        //   canvas4 = document.getElementById('canvas23');
        // }
        if (this.docTypeId == 1 || this.docTypeId == 2) {
          // QRCode.toCanvas(canvas1, this.form[0].qr_code, { width: 90 }, error => {});
          // QRCode.toCanvas(canvas2, this.form[0].qr_code, { width: 90 }, error => {});
          // if (this.data2) {
          //   QRCode.toCanvas(canvas3, this.form[1].qr_code, { width: 90 }, error => {});
          //   QRCode.toCanvas(canvas4, this.form[1].qr_code, { width: 90 }, error => {});
          // }
          setTimeout(() => {
            this.createForm();
          }, 0.5);
        } else {
          this.createForm();
        }
      }
    },

    createForm() {
      printJS({
        printable: 'printJS-form',
        type: 'html',
        // type:'pdf',
        // header: 'PrintJS - Form Element Selection',
        font_size: '13px;',
        // font_size: '11px;',
        maxWidth: '80mm',
        style: `
            @page { size: 80mm auto; margin-top:1px; margin-bottom:1px; margin-left: 1px; margin-right: 1px;}
            .alert { color: #FF5252; line-height: 0.6; margin-bottom: 5px; margin-top: 3px; }
            .border-radius { border-radius: 8px; border: solid 1px; padding: 2px 5px 2px 5px; margin-top:5px}
            .line { border-top: dashed  black 1px; margin-top: 5px; margin-bottom: 5px; }
            .line-s { border-top:  dashed  black 1px; margin-top: 2px; margin-bottom: 2px; }
            .line-b { border-bottom: dashed  black 1px;}
            .product-margin {margin-bottom: 10px;margin-top: 10px ; }
            .line-bold { border-bottom: solid black 1.5px; }
            .font-size-12 { font-size: 11px!important; }
            .text-header { font-size: 13px!important; }
            .text-header-detail { font-size:11px!important;}
             .text-product-detail { font-size:12px!important;}
               .text-product { font-size:13px!important;}
            .text-project { font-size: 13.5px!important; font-weight: 500; }
            .text-carPlate{ font-size: 16px!important; font-weight: 500; margin-top:3px;margin-bottom:5px }
            .table-td {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-left: none!important;
              border-right: none!important;
            }
            .pt-0 {
              padding-top: 0px;
            }
            .pb-0 {
              padding-bottom: 0px;
            }
            .pb--2 {
              margin-top: -2px;
            }
            .mb5 {
              margin-bottom: 10px!important;
            }
            .mt2 {
              margin-top: 2px!important;
            }
            .mt--3 {
              margin-top: -3px!important;
            }
            .mt-0 {
              margin-top: 0px!important;
            }
            .mt-3 {
              margin-top: 3px!important;
            }
            .mx-5 {
              margin-right: 5px!important;
              margin-left: 5px!important;
            }
            .mb-0 {
              margin-bottom: 0px!important;
            }
            .mb-3 {
              margin-bottom: 3px!important;
            }
            .my-0 {
              margin-top: 0px!important;
              margin-bottom: 0px!important;
            }
            .my-5 {
              margin-top: 5px!important;
              margin-bottom: 5px!important;
            }
            .my-10 {
              margin-top: 10px!important;
              margin-bottom: 10px!important;
            }
            .my-3 {
              margin-top: 3px!important;
              margin-bottom: 3px!important;
            }
            .mr-3 {
              margin-right: 3px;
            }
            .ml-5 {
              margin-left: 5px;
            }
            table, th, td {
              border-collapse: collapse;
            }
            tbody.invoice-tbody tr td { 
              font-size: 11px!important; 
            }
            .recieve_date{
              font-size: 13px!important;
            }
            .subtitle {
              font-size: 10px!important;
            }
            .align-left {
              float: left;
            }
            .align-right {
              float: right;
            }
            .tr-line { border-bottom: solid 1px #ccc;}

            tr.note td label {
              font-size: 10px!important;
            }
            .checkbox-title {
              vertical-align: middle;
              margin-left: -5px;
            }
            .text-right {
              text-align: right;
            }
            .bill-container{
              height: 950px;
            }
            input[type=checkbox] {
                transform: scale(0.5);
                vertical-align: middle;
            }
            .text-note {
            word-wrap: break-word;
            word-break: break-all;
            }
            .customer { line-height: 1.6;}
            .text-center {text-align: center;}
            .text-left {text-align: left;}
           .pagebreak { page-break-before: always;}
            `,
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;

      } catch (error) {
        console.error('Error getUserGroup in print:', error);
        Sentry.captureException(error);
        this.permission.show_phone_number = true;
      };
    },
  },
  computed: {
    documentName() {
      if (this.docTypeId == 0) return 'ใบเสนอราคา';
      else if (this.docTypeId == 1) return 'ใบแจ้งหนี้';
      else if (this.docTypeId == 2) return 'ใบเสร็จรับเงิน';
      else if (this.docTypeId == 3) return 'ใบลดหนี้';
      else if (this.docTypeId == 4) return 'ใบเพิ่มหนี้';
      return this.docTypeId;
    },
    primaryColor() {
      // if (this.type == "ใบเสนอราคา") return "#7225dd";
      // else if (this.type == "ใบแจ้งหนี้") return "#d86f07";
      // else if (this.type == "ใบเสร็จรับเงิน") return "#048bff";
      // else if (this.type == "ใบเพิ่มหนี้") return "red";
      // else if (this.type == "ใบลดหนี้") return "#49aa1c";
      return 'black';
    },
    // signatureLeft() {
    //   // if (this.type == "ใบเสนอราคา") return "ผู้สั่งซื้อ";
    //   // else if (this.type == "ใบแจ้งหนี้") return "ผู้รับแจ้งหนี้";
    //   // else if (this.type == "ใบเสร็จรับเงิน") return "ผู้จ่ายเงิน";
    //   // else if (this.type == "ใบเพิ่มหนี้") return "ผู้จ่ายเงิน";
    //   // else if (this.type == "ใบลดหนี้") return "ผู้จ่ายเงิน";
    //   return 'ผู้สั่งซื้อ';
    // },
    // signatureRight() {
    //   // if (this.type == "ใบเสนอราคา") return "ผู้เสนอราคา";
    //   // else if (this.type == "ใบแจ้งหนี้") return "ผู้แจ้งหนี้";
    //   // else if (this.type == "ใบเสร็จรับเงิน") return "ผู้รับเงิน";
    //   // else if (this.type == "ใบเพิ่มหนี้") return "ผู้เสนอราคา";
    //   // else if (this.type == "ใบลดหนี้") return "ผู้เสนอราคา";
    //   return 'ผู้เสนอราคา';
    // },
    // taxProducts() {
    //   let sum = 0;
    //   if (this.setting.vat_registration) {
    //     for (let j = 0; j < this.form[1].original_product.length; j++) {
    //       if (this.form[1].original_product[j].vat == 1) {
    //         sum +=
    //           this.form[1].original_product[j].price -
    //           this.form[1].original_product[j].vat_price;
    //       }
    //     }
    //     return sum;
    //   }
    // },
    // taxFreeProducts() {
    //   let sum = 0;
    //   if (this.setting.vat_registration) {
    //     for (let j = 0; j < this.form[1].original_product.length; j++) {
    //       if (this.form[1].original_product[j].vat == 0) {
    //         sum += this.form[i].original_product[j].price;
    //       }
    //     }
    //     return sum;
    //   }
    // },
  },
  mounted() {
    console.log('show_qr_code_payment ' + this.setting.show_qr_code_payment);
  }
};
</script>

<style lang="scss">
#printJS-form {
  position: relative;
}
.pagebreak {
  page-break-after: always;
}
</style>
